namespace app.pages.services {
        "use strict";

        export interface IPageService {
                readRecord(recordKey: number): ng.IPromise<pages.interfaces.IPage>;
        }

        class PageService extends app.core.services.BaseService implements IPageService {
                private serviceBase: string = "/api/page/";

                static $inject: string[] = ["$http", "$q", "notificationLoggingService"];
                constructor($http: ng.IHttpService, $q: ng.IQService, notificationLoggingService: blocks.errorLogging.INotificationLoggingService) {
                        super($http, $q, notificationLoggingService)
                }

                readRecord(recordKey: number): ng.IPromise<pages.interfaces.IPage> {
                        return this.$http
                                .get(this.serviceBase + "read/" + recordKey)
                                .then((response: ng.IHttpPromiseCallbackArg<pages.interfaces.IPage>): pages.interfaces.IPage => response.data)
                                .catch((error) => this.logError(error));
                };
        }

        angular
                .module("app.pages.services")
                .service("pageService", PageService);
}