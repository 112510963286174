namespace app.pages.services {
        "use strict";

        export interface IDocumentIssueService {
                readAllRecords(): ng.IPromise<interfaces.IDocumentIssue[]>;
                readChildren(parent: number): ng.IPromise<interfaces.IDocumentIssue[]>;
                readRecord(recordKey: number): ng.IPromise<interfaces.IDocumentIssue>;
        }

        class DocumentIssueService extends app.core.services.BaseService implements IDocumentIssueService {
                private serviceBase: string = "/api/documentissue/";

                static $inject: string[] = ["$http", "$q", "notificationLoggingService"];
                        constructor(
                        $http: ng.IHttpService,
                        $q: ng.IQService,
                        notificationLoggingService: blocks.errorLogging.INotificationLoggingService) {
                        super($http, $q, notificationLoggingService)
                }

                readAllRecords(): ng.IPromise<interfaces.IDocumentIssue[]> {
                        return this.$http
                                .get(this.serviceBase + "readall")
                                .then((response: ng.IHttpPromiseCallbackArg<interfaces.IDocumentIssue[]>): interfaces.IDocumentIssue[] => response.data)
                                .catch((error) => this.logError(error));
                };

                readChildren(parent: number): ng.IPromise<interfaces.IDocumentIssue[]> {
                        var record = {
				parentID: parent
			}
                        return this.$http
                                .post(this.serviceBase + "readchildren/", record)
                                .then((response: ng.IHttpPromiseCallbackArg<interfaces.IDocumentIssue[]>): interfaces.IDocumentIssue[] => response.data)
                                .catch((error) => this.logError(error));                        
                }

                readRecord(recordKey: number): ng.IPromise<interfaces.IDocumentIssue> {
                        return this.$http
                                .get(this.serviceBase + "read/" + recordKey)
                                .then((response: ng.IHttpPromiseCallbackArg<interfaces.IDocumentIssue>): interfaces.IDocumentIssue => response.data)
                                .catch((error) => this.logError(error));
                };                
        }

        angular
                .module("app.pages.services")
                .service("documentIssueService", DocumentIssueService);
}