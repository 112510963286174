namespace app.widgets {
    "use strict";

    export interface IWidgetRefreshValues {
        cwnMenuGroups: boolean;
        cwnHeaderMenu: boolean;
        cwnUserPref: boolean;
        cwnNewMeetingInvitationCount: boolean;
    }

    angular
        .module("app.widgets")
        .value("widgetRefresh", <IWidgetRefreshValues> {
            cwnMenuGroups: false,
            cwnHeaderMenu: false,
            cwnNewMeetingInvitationCount: false,
	        cwnUserPref: false
        })
}