namespace app.documents {
    "use strict";

    export interface IDocumentCategoryConstants {
        financeDocuments: number;
        homepageDocuments: number;
        operatingRulesDocuments: number;
        procedures: number;
        newsletterDocuments: number;
    }

    angular
        .module("app.documents")
        .constant("documentCategoryConstants", <IDocumentCategoryConstants>{
            financeDocuments: 61,
            homepageDocuments: 9,
            operatingRulesDocuments: 10,
            procedures: 2192,
            newsletterDocuments: 9899
        });
}
