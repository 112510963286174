namespace app.pages.services {
        "use strict";

        export interface IDocumentIssueDocumentTypeService {
                readAllRecords(): ng.IPromise<interfaces.IDocumentIssueDocumentType[]>;
                readRecord(recordKey: number): ng.IPromise<interfaces.IDocumentIssueDocumentType>;
        }

        class DocumentIssueDocumentTypeService extends app.core.services.BaseService implements IDocumentIssueDocumentTypeService {
                private serviceBase: string = "/api/documentissuedocumenttype/";

                static $inject: string[] = ["$http", "$q", "notificationLoggingService"];
                constructor(
                        $http: ng.IHttpService,
                        $q: ng.IQService,
                        notificationLoggingService: blocks.errorLogging.INotificationLoggingService) {
                        super($http, $q, notificationLoggingService)
                }

                readAllRecords(): ng.IPromise<interfaces.IDocumentIssueDocumentType[]> {
                        return this.$http
                                .get(this.serviceBase + "readall")
                                .then((response: ng.IHttpPromiseCallbackArg<interfaces.IDocumentIssueDocumentType[]>): interfaces.IDocumentIssueDocumentType[] => response.data)
                                .catch((error) => this.logError(error));
                };               
                
                readRecord(recordKey: number): ng.IPromise<interfaces.IDocumentIssueDocumentType> {
                        return this.$http
                                .get(this.serviceBase + "read/" + recordKey)
                                .then((response: ng.IHttpPromiseCallbackArg<interfaces.IDocumentIssueDocumentType>): interfaces.IDocumentIssueDocumentType => response.data)
                                .catch((error) => this.logError(error));
                };      
        }

        angular
                .module("app.pages.services")
                .service("documentIssueDocumentTypeService", DocumentIssueDocumentTypeService);
}