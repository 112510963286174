namespace app.publicPages {
	angular
		.module("app.subscribers")
		.config(routeConfig);

	routeConfig.$inject = ["$routeProvider", "claimsConstants"];
	function routeConfig($routeProvider: ng.route.IRouteProvider, claimsConstants: app.core.security.IClaimsConstants): void {
		$routeProvider
			.when("/members", <app.core.router.ICwnRoute>{
				templateUrl: "/app/subscribers/subscriberList/subscriberList.html",
				controller: "SubscriberListController",
				controllerAs: "vm",
				title: "Members",
				accessLevels: [claimsConstants.LoggedIn],
				requireAuthentication: true
			});
	}
}