namespace app.widgets {
	"use strict";

	interface ICwnIssueDocumentVm {
		newComment: string;
		saveComment(docID: number): void;
	}

	class CwnIssueDocumentController implements ICwnIssueDocumentVm {
		newComment: string;
		static $inject: string[] = ["$route", "$scope", "$q", "documentService"];
		
		constructor(private $route: ng.route.IRouteService,
			private $scope: ng.IScope,
			private $q: ng.IQService,
			private documentService: documents.services.IDocumentService)
		{
		}
			
		saveComment(docID: number) {
			if (this.newComment === "")
				return;
			
			let promises: ng.IPromise<any>[] = [
				this.documentService.saveComment(docID, this.newComment)
			];

			this.newComment = "";
			this.$q.all(promises);
			this.$route.reload();
			$(".modal-backdrop").hide();
		}
	}

	interface IDirectiveScope {
		document: string;
	}

	class CwnIssueDocument implements ng.IDirective {
		static instance(): ng.IDirective {
			return new CwnIssueDocument;
		}

		scope: IDirectiveScope = {
			document: "="
		};

		restrict: string = "E";
		templateUrl: string = "/app/widgets/cwnIssueDocument/cwnIssueDocument.html";
		controllerAs: string = "vm";
		bindToController: boolean = true;

		controller = CwnIssueDocumentController;
	}

	angular
		.module("app.widgets")
		.directive("cwnIssueDocument", CwnIssueDocument.instance);
}