namespace app.pages {
    "use strict";

    interface ILegalOperatingRulesVm {
        page: pages.interfaces.IPage;
        formLoading: boolean;
        archived: boolean;
        bindData: boolean;
        refreshData: boolean;
        documentArchivedClick(): void;
    }

    class LegalOperatingRulesController implements ILegalOperatingRulesVm {
        page: pages.interfaces.IPage;
        formLoading: boolean = true;
        archived: boolean = false;
        bindData: boolean = false;
        refreshData: boolean = false;

        static $inject: string[] = ["$location", "$q", "documentCategoryConstants", "documentService", "pageConstants", "pageService", "refreshTokenService", "widgetRefresh"];
        constructor(
            private $location: ng.ILocationService,
            private $q: ng.IQService,
            private documentCategoryConstants: documents.IDocumentCategoryConstants,
            private documentService: documents.services.IDocumentService,
            private pageConstants: IPageConstants,
            private pageService: services.IPageService,
            private refreshTokenService: core.security.services.IRefreshTokenService,
            private widgetRefresh: widgets.IWidgetRefreshValues) {

            refreshTokenService.refreshToken().then(() => {
                this.activate();
            });
        }

        activate() {
            this.widgetRefresh.cwnMenuGroups = true;
            this.bindData = true;

            let promises: ng.IPromise<any>[] = [
                this.pageService.readRecord(this.pageConstants.LegalOperatingRules)
            ];

            return this.$q
                .all(promises)
                .then((results) => this.writeFormValues.apply(this, results));
        }

        writeFormValues(page: pages.interfaces.IPage) {
            this.page = page;
            this.formLoading = false;
        }

        documentArchivedClick() {
            this.archived = !this.archived;
            this.refreshData = true;
        }

        searchData = (recordsPerPage: number, pageNumber: number) => {
            var searchParameters: core.interfaces.ISearchParameters<documents.interfaces.IDocument> = {
                recordsPerPage: recordsPerPage,
                pageNumber: pageNumber,
                filter: {
                    id: 0,
                    archived: this.archived
                }
            };

            return this
                .documentService
                .readByDocumentCategoryKey(this.documentCategoryConstants.operatingRulesDocuments, searchParameters);
         }        
    }

    angular
        .module("app.pages")
        .controller("LegalOperatingRulesController", LegalOperatingRulesController);
}