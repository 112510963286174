namespace app.pages {
    "use strict";

    interface IIssuesPageVm {
        page: pages.interfaces.IPage;
        formLoading: boolean;
        archived: boolean;
        bindData: boolean;
        refreshData: boolean;
        documentIssues: interfaces.IDocumentIssue[];    // Children only
        documentIssue: interfaces.IDocumentIssue;       // Current one
        documentIssueDocumentTypes: interfaces.IDocumentIssueDocumentType[];
        documentIssueDocumentType: interfaces.IDocumentIssueDocumentType;       // Current one again
        documentIssueID: number;
        documentIssueDocumentTypeID: number;
        documentArchivedClick(): void;
    }

    class IssuesPageController implements IIssuesPageVm {
        page: pages.interfaces.IPage;
        formLoading: boolean = true;
        archived: boolean = false;
        bindData: boolean = false;
        refreshData: boolean = false;
        documentIssues: interfaces.IDocumentIssue[];
        documentIssue: interfaces.IDocumentIssue;
        documentIssueDocumentTypes: interfaces.IDocumentIssueDocumentType[];
        documentIssueDocumentType: interfaces.IDocumentIssueDocumentType;
        documentIssueID: number;
        documentIssueDocumentTypeID: number;        

        static $inject: string[] = ["$location", "$q", "$routeParams", "documentCategoryConstants", "documentService", "pageConstants", "pageService", "refreshTokenService", "widgetRefresh", "documentIssueService", "documentIssueDocumentTypeService"];
        constructor(
            private $location: ng.ILocationService,
            private $q: ng.IQService,
            private $routeParams: interfaces.IPagesRouteParams,
            private documentCategoryConstants: documents.IDocumentCategoryConstants,
            private documentService: documents.services.IDocumentService,
            private pageConstants: IPageConstants,
            private pageService: services.IPageService,
            private refreshTokenService: core.security.services.IRefreshTokenService,
            private widgetRefresh: widgets.IWidgetRefreshValues,
            private documentIssueService: services.IDocumentIssueService,
            private documentIssueDocumentTypeService: services.IDocumentIssueDocumentTypeService) {

                this.documentIssueID = parseInt(this.$routeParams.documentissueid);
                this.documentIssueDocumentTypeID = parseInt(this.$routeParams.documentissuedocumenttypeid);
                
                refreshTokenService.refreshToken().then(() => {
                        this.activate();
                });
        }

        activate() {
            this.widgetRefresh.cwnMenuGroups = true;
            this.bindData = true;

            let promises: ng.IPromise<any>[] = [
                this.pageService.readRecord(this.pageConstants.Issues),
                this.documentIssueService.readChildren(this.documentIssueID),
                this.documentIssueDocumentTypeService.readAllRecords(),
                this.documentIssueService.readRecord(this.documentIssueID),
                this.documentIssueDocumentTypeService.readRecord(this.documentIssueDocumentTypeID)
            ];

            return this.$q
                .all(promises)
                .then((results) => this.writeFormValues.apply(this, results));
        }

        writeFormValues(page: pages.interfaces.IPage, documentIssues: interfaces.IDocumentIssue[], documentIssueDocumentTypes: interfaces.IDocumentIssueDocumentType[], documentIssue: interfaces.IDocumentIssue, documentIssueDocumentType: interfaces.IDocumentIssueDocumentType) {
            this.page = page;
            this.formLoading = false;
            this.documentIssues = documentIssues;
            this.documentIssueDocumentTypes = documentIssueDocumentTypes;
            this.documentIssue = documentIssue;
            this.documentIssueDocumentType = documentIssueDocumentType;
        }

        documentArchivedClick() {
            this.archived = !this.archived;
            this.refreshData = true;
        }

        searchData = (recordsPerPage: number, pageNumber: number) => {
            var searchParameters: core.interfaces.ISearchParameters<documents.interfaces.IDocument> = {
                recordsPerPage: recordsPerPage,
                pageNumber: pageNumber,
                filter: {
                    id: 0,
                    archived: this.archived,
                    documentissuedocumenttypeid: this.documentIssueDocumentTypeID,
                    documentissueid: this.documentIssueID,
                },
            };

            return this
                .documentService
                .readIssueDocuments(searchParameters);
         }
    }

    angular
        .module("app.pages")
        .controller("IssuesPageController", IssuesPageController);
}