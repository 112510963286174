namespace app.widgets {
	"use strict";

	interface ICwnHeaderMemberVm {
		loggedIn: boolean;
		subscriberName: string;
	}

	class CwnHeaderMemberController implements ICwnHeaderMemberVm {
		loggedIn: boolean;
		subscriberName: string;

		static $inject: string[] = ["$scope", "userCredentials"];
		constructor(private $scope: ng.IScope, private userCredentials: app.core.security.IUserCredentials) {
			this.loggedIn = this.userCredentials.loggedIn;

			this.$scope.$watch(
				() => this.userCredentials.loggedIn,
				(value) => {
					this.loggedIn = value;

					if (this.loggedIn === true) {
						this.subscriberName = this.userCredentials.getLoggedInUser();
					}
				});
		}
	}

	interface IDirectiveScope {
	}

	class CwnHeaderMember implements ng.IDirective {
		static instance(): ng.IDirective {
			return new CwnHeaderMember;
		}

		scope: IDirectiveScope = {
		};

		restrict: string = "E";
		templateUrl: string = "/app/widgets/cwnHeaderMember/cwnHeaderMember.html";
		controllerAs: string = "vm";
		bindToController: boolean = true;
		controller = CwnHeaderMemberController;
	}

	angular
		.module("app.widgets")
		.directive("cwnHeaderMember", CwnHeaderMember.instance);
}