namespace app.widgets {
	"use strict";

	interface ICwnUserPrefVm {
		inhibitNotifications: boolean;
        setInhibitNotificationStatus(): void;
	}

	class CwnUserPrefController implements ICwnUserPrefVm {
		inhibitNotifications: boolean;
		
		static $inject: string[] = ["$scope", "$q", "subscriberService", "widgetRefresh"];
		constructor(private $scope: ng.IScope,
					private $q: ng.IQService,
					private subscriberService: subscribers.services.ISubscriberService,
                    private widgetRefresh: IWidgetRefreshValues) 
		{	
			this.$scope.$watch(
				() => this.widgetRefresh.cwnUserPref,
				(refresh) => {
					if (refresh === true) {
                        this.readInhibitNotificationStatus();
					}
				});
		}
		
		readInhibitNotificationStatus() {
            this.subscriberService
                .getInhibitNotificationStatus()
                .then((inhibit: boolean) => {
                    this.widgetRefresh.cwnUserPref = false;
                    this.inhibitNotifications = inhibit;
                });
        }
		
		setInhibitNotificationStatus() {
			let promises: ng.IPromise<any>[] = [
                this.subscriberService.setInhibitNotificationStatus(this.inhibitNotifications)
            ];

            return this.$q.all(promises);
		}		
	}
	
	interface IDirectiveScope {
	}

	class CwnUserPref implements ng.IDirective {
		static instance(): ng.IDirective {
			return new CwnUserPref;
		}

		scope: IDirectiveScope = {
		};

		restrict: string = "E";
		templateUrl: string = "/app/widgets/cwnUserPref/cwnUserPref.html";
		controllerAs: string = "vm";
		bindToController: boolean = true;
		controller = CwnUserPrefController;
	}
	
	angular
		.module("app.widgets")
		.directive("cwnUserPref", CwnUserPref.instance);
}
